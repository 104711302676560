var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "repay-base" },
    [
      _c("div", { staticClass: "money-card" }, [
        _c("span", { staticClass: "money-title" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.getI18n("payTitle")) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _c(
            "span",
            { staticClass: "money-discount", style: { color: _vm.moneyColor } },
            [
              _vm._v(
                "\n                $ " +
                  _vm._s(_vm._f("numberWithCommasFixed2")(_vm.discountMoney)) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showAllMoney
            ? _c("del", { staticClass: "money-all" }, [
                _vm._v(
                  "\n                $ " +
                    _vm._s(_vm._f("numberWithCommasFixed2")(_vm.allMoney)) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "account-card" }, [
        _c("div", { staticClass: "number-code" }, [
          _vm.repayInfo.length > 0
            ? _c("div", { staticClass: "collapse-wrap" }, [
                _c("div", {
                  staticClass: "collapse-bar",
                  style: {
                    background:
                      _vm.moneyColor !== "#1fc160" ? "#E6E9F0" : "#E3F2F2",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "collapse" },
                  [
                    _c(
                      "van-collapse",
                      {
                        attrs: { accordion: true, border: false },
                        on: { change: _vm.changeCollapse },
                        model: {
                          value: _vm.activeNames,
                          callback: function ($$v) {
                            _vm.activeNames = $$v
                          },
                          expression: "activeNames",
                        },
                      },
                      _vm._l(_vm.repayInfo, function (item, index) {
                        return _c(
                          "van-collapse-item",
                          {
                            key: index,
                            class: {
                              noBorderTop:
                                index === _vm.currentIndex + 1 &&
                                item.showCollapseItem,
                            },
                            attrs: { name: item.accountNumber },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function () {
                                    return [
                                      _c("div", { staticClass: "title-left" }, [
                                        _c("div", {
                                          staticClass: "title-border",
                                          style: {
                                            "border-color":
                                              "transparent transparent transparent " +
                                              _vm.moneyColor,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "title-text" },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.getI18n("shouldPayCash", [
                                                    index + 1,
                                                  ])
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "title-cash",
                                            class: {
                                              "title-cash-active":
                                                item.showCollapseItem &&
                                                _vm.currentIndex === index,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        $ " +
                                                _vm._s(
                                                  _vm._f(
                                                    "numberWithCommasFixed2"
                                                  )(item.repayCodeAmount)
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "collapse-body" },
                              [
                                _c("barCode", {
                                  attrs: { "code-value": _vm.codeValue },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "account-code" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm._f("accountNumber")(
                                          item.accountNumber,
                                          _vm.splitSign
                                        )
                                      ) +
                                      "\n                                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.utcTime
                                  ? _c("div", { staticClass: "desc-wrap" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "utc-time",
                                          style: { color: _vm.moneyColor },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.utcTime + " - 24:00") +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "time-desc" }, [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.getI18n("timeDescList")
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("extra"),
      _vm._v(" "),
      _vm.instructionKey
        ? _c(
            "div",
            { staticClass: "instruction" },
            [
              _c("yqg-md-preview", {
                attrs: { "md-text": _vm.instruction.content || "" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ec-overlay",
        {
          staticClass: "paycash-modal modal",
          attrs: { visible: _vm.modalVisible },
        },
        [
          _c("div", { staticClass: "wrapper" }, [
            _c("div", { staticClass: "content-wrapper" }, [
              _c("img", { attrs: { src: require("./img/bill.png") } }),
              _vm._v(" "),
              _c("div", { staticClass: "multi-title" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.getI18n("multiModalTitle")) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "multi-text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.getI18n("multiModalText", [
                      _vm.moneyColor,
                      "10000 pesos",
                      _vm.moneyColor,
                      (_vm.repayInfo.length || 0) + " códigos",
                    ])
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "multi-btn",
                  style: { background: _vm.moneyColor },
                  on: { click: _vm.closeModal },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.getI18n("multiModalBtn")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "close",
              attrs: { src: require("./img/close.png"), alt: "" },
              on: { click: _vm.closeModal },
            }),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
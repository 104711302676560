var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "wrap" }, [
    _c(
      "div",
      {
        ref: "content",
        staticClass: "move-content",
        on: {
          touchstart: function ($event) {
            $event.preventDefault()
            return _vm.touchStart.apply(null, arguments)
          },
          touchmove: function ($event) {
            $event.preventDefault()
            return _vm.touchMove.apply(null, arguments)
          },
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
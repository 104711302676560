<template>
    <div ref="wrap">
        <div
            ref="content"
            class="move-content"
            @touchstart.prevent="touchStart"
            @touchmove.prevent="touchMove"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Horseman',

    props: {
        duration: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            dis: 0,
            preFrame: 0,
            nowPos: 0,
            startPos: 0,
            targetDis: 0,
            timer: null
        };
    },

    beforeDestroy() {
        if (this.timer) clearTimeout(this.timer);
    },

    methods: {
        start() {
            this.$nextTick(() => {
                const {duration} = this;
                this.dis = this.$refs.content.offsetWidth;
                this.targetDis = this.dis - this.$refs.wrap.offsetWidth;
                this.preFrame = this.targetDis / (duration * 50);
                this.move();
            });
        },

        move() {
            this.timer = setTimeout(() => {
                let newPos = this.nowPos + this.preFrame;
                this.nowPos = newPos;
                if (this.nowPos >= this.targetDis) {
                    this.nowPos = this.targetDis;
                    newPos = this.targetDis;
                    this.domMove(newPos);

                    return;
                }

                this.domMove(newPos);
                this.move();
            }, 20);
        },

        domMove(pos) {
            this.$refs.content.style.transform = `translateX(-${pos}px)`;
        },

        touchStart(event) {
            if (this.timer) clearTimeout(this.timer);
            this.startPos = event.targetTouches[0].clientX;
        },

        touchMove(event) {
            const targetPos = event.targetTouches[0].clientX;
            const dis = targetPos - this.startPos;
            this.nowPos -= dis;
            if (this.nowPos < 0) {
                this.nowPos = 0;
                this.startPos = 0;
            } else if (this.nowPos > this.targetDis) {
                this.nowPos = this.targetDis;
                this.startPos = this.targetDis;
            } else {
                this.startPos = targetPos;
            }

            this.domMove(this.nowPos);
        }
    }

};
</script>

<style lang="scss" scoped>
.move-content {
    display: inline-flex;
    flex-wrap: nowrap;
}
</style>

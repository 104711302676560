/*
 * @Author: ruiwang
 * @Date: 2020-04-29 14:44:08
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-09-20 17:20:11
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from '../constant/config';

const api = {
    getHost: getEurekaHost,

    getByKey: {
        url: '/api/operation/staticText/getByKey',
        method: 'get'
    }
};

export default Resource.create(api);

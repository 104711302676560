<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-8-6 10:53:53 -->
<!-- @desc ec-overlay.vue -->

<template>
    <van-overlay
        :lock-scroll="lockScroll"
        :show="visible"
        class="ec-overlay"
        @click="handleClick"
    >
        <div
            class="modal-wrapper"
            @click.stop
        >
            <slot />
        </div>
    </van-overlay>
</template>

<script>
import {Overlay} from 'vant';

export default {
    name: 'EcOverlay',

    components: {
        [Overlay.name]: Overlay
    },

    model: {
        prop: 'visible',
        event: 'change'
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        },

        closeOnClickOverlay: {
            type: Boolean,
            default: true
        },

        lockScroll: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        handleClick() {
            if (this.closeOnClickOverlay) this.$emit('change', false);
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.ec-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>

<!-- @Author: chengyuzhang -->
<!-- @Date: 2021-3-16 14:12:29 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-11-30 12:09:46 -->

<template>
    <component
        :is="MethodConfig[method]"
        :repay-info="repayInfoProps"
        :pay-type="Array.isArray(repayInfoProps) && repayInfoProps.length > 1 ? 'RepayBaseList' : 'RepayBase'"
        :channel-list="repayInfo.channelList"
        :class="{'repay_paychash': isMultiPay, 'repay': !isMultiPay}"
    />
</template>

<script>
import Repay from 'ssr-common/resource/repay';
import {numSub} from 'ssr-common/util/number';
import Arcus from 'ssr-common/vue/component/arcus';
import ChannelUnavailable from 'ssr-common/vue/component/channel-unavailable';
import Oxxo from 'ssr-common/vue/component/oxxo';
import Paycash from 'ssr-common/vue/component/paycash';
import Paynet from 'ssr-common/vue/component/paynet';
import Stp from 'ssr-common/vue/component/stp';
import parseUa from 'ssr-common/vue/mixin/parse-ua';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import Cashloan from 'olacred/common/resource/cashloan';

import ErrorComp from './error';

const MethodConfig = {
    LIQUIDO_STP: Stp,
    LIQUIDO_ARCUS: Arcus,
    OXXO_CASH: Oxxo,
    PAYNET: Paynet,
    STP: Stp,
    PAY_CASH: Paycash,
    ERROR: ErrorComp,
    UNAVAILABLE: ChannelUnavailable,
    DIRECT_PAYCASH: Paycash,
    OPM: Stp,
    DONDE: Stp
};

export default {
    name: 'Repay',

    mixins: [prefetch('repayInfo'), parseUa()],

    provide() {
        return {
            paycashInstruction: 'OLACRED_PAY_CASH_INSTRUCTIONS',
            repayInfoMap: this.repayInfoMap
        };
    },

    data() {
        return {
            MethodConfig,
            paramsChange: false
        };
    },

    computed: {
        ignoreDeduct() {
            const {isIOS, versionCode} = this;

            return isIOS && versionCode < 20601;
        },

        isMultiPay() {
            return this.repayInfo.isMultiPay; // 多笔支付返回数组，否则返回对象（数组长度为1为单笔支付）
        },

        method() {
            const {
                $route: {params: {method}},
                repayInfo: {error, channelList},
                repayInfoMap,
                paramsChange
            } = this;

            if (paramsChange) return method;
            if (channelList?.includes(method)) return 'UNAVAILABLE';

            if (error
                || (this.isMultiPay && (this.repayInfoList.length === 0 || this.repayInfoList.some(item => item.accountNumber === null)))
                || (!this.isMultiPay && repayInfoMap[method]?.accountNumber === null)
            ) return 'ERROR';

            return method;
        },

        repayInfoMap() {
            const {
                repayInfo
            } = this;
            if (this.isMultiPay) {
                return Object.values(repayInfo)?.reduce((acc, repay) => {
                    acc.push({
                        [repay?.channelType]: {...repay}
                    });

                    return acc;
                }, []);
            }

            return Object.values(repayInfo)?.reduce((acc, repay) => {
                acc[repay?.channelType] = {
                    ...repay
                };

                return acc;
            }, {});
        },

        repayInfoList() {
            const {
                $route: {params: {method}}
            } = this;
            if (this.isMultiPay) {
                return Object.values(this.repayInfoMap)?.reduce((acc, repay) => {
                    if (repay[method]) {
                        acc.push(repay[method]);
                    }

                    return acc;
                }, []);
            }

            return [];
        },

        repayInfoProps() {
            const {
                $route: {params: {method}}
            } = this;
            if (this.isMultiPay) {
                if (this.repayInfoList?.length > 1) {
                    return this.repayInfoList;
                }

 if (this.repayInfoList?.length === 1) {
                    return this.repayInfoList[0] || {};
                }

                return this.repayInfo.error;
            }

            return this.repayInfoMap[method] || this.repayInfo.error;
        }
    },

    watch: {
        '$route.params.method': async function () {
            this.paramsChange = true;
            await this.requestData();
            this.paramsChange = false;
        }
    },

    methods: {
        async initFetch({header} = {}) {
            const {query} = this.$route;
            const isInstallment = !!query.channelType;
            const isInstalmentIds = !!query.instalmentIds;

            let getAccount = this.getRepaymentAccountByChannel;
            let params = {};
            if (isInstallment) {
                // eslint-disable-next-line no-unused-vars
                const entries = Object.entries(query)?.filter(([key, value]) => value !== 'null');
                params = Object.fromEntries(entries);
            }

            if (isInstalmentIds) {
                getAccount = this.getRepaymentAccountByChannelV3;
            }

            const {channelList} = await this.fetchRepayShow();

            if (channelList?.includes(this.$route.params.method)) {
                return {channelList};
            }

            const {data: {body} = {}, error} = await getAccount(params, header, isInstallment)
                .catch(({data: {status} = {}}) => ({error: status}));

            const isMultiPay = Array.isArray(body);
            let compatibleBody = {};
            if (isMultiPay) {
                compatibleBody = body;
                compatibleBody.forEach((item, index) => {
                    item.showCollapseItem = index === 0;
                });
            } else {
                compatibleBody = isInstallment ? [body] : body;
            }

            return {...compatibleBody, error, isMultiPay, channelList};
        },

        async fetchRepayShow() {
            const keys = 'app_config.channel_filter_list';
            const {data: {body: {configs}}} = await Repay.generalConfig({
                params: {keys}
            }).catch(() => ({data: {body: {configs: []}}}));
            const {configValue} = configs.find(({configName}) => configName === keys) ?? {};

            return {channelList: configValue?.split(',') ?? []};
        },

        async getRepaymentAccountByChannel(params, headers, isInstallment) {
            let api = Cashloan.getRepayVirtualAccount;
            if (isInstallment) api = Cashloan.getRepaymentAccountByChannel;

            const res = await api({headers, params});

            return res;
        },

        async getRepaymentAccountByChannelV3(params, headers) {
            const {instalmentIds, channelType} = params;
            params.instalmentIds = instalmentIds.slice(1, instalmentIds.length - 1).split(', ');
            params.channel = channelType;
            if (params.finalAmount) {
                params.amount = params.finalAmount;
                delete params.finalAmount;
            }

            if (!this.ignoreDeduct && params.deductAmount) {
                params.amount = numSub(params.amount, params.deductAmount);
            }

            const res = await Cashloan.getRepaymentAccountByChannelV3(params, {headers});

            return res;
        }
    }
};
</script>

<style lang="scss" scoped>
    div.repay_paychash {
        padding: 0.1rem;
    }

    div.repay {
        padding: 0.1rem 0.25rem;
    }
</style>

/*
 * @Author: zhaoyang
 * @Date: 2022-09-22 17:38:34
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2022-10-25 16:09:20
 */

import {numSub} from 'ssr-common/util/number';
import {numberWithCommasFixed2} from 'ssr-common/util/string';
import {isOlacred, isMayacash} from 'ssr-common/util/webview/ua';
import parseUa from 'ssr-common/vue/mixin/parse-ua';

export default {
    mixins: [parseUa()],

    filters: {
        numberWithCommasFixed2
    },

    computed: {
        ignoreDeduct() {
            const {isIOS, isYqdCredMex, versionCode} = this;

            return isIOS && isYqdCredMex && versionCode < 20601;
        },

        showAllMoney() {
            const {deductAmount} = this.$route.query;

            return !this.ignoreDeduct && deductAmount && deductAmount !== '0';
        },

        allMoney() {
            const {amount, finalAmount} = this.$route.query;

            return Number(finalAmount ?? amount);
        },

        discountMoney() {
            const {deductAmount} = this.$route.query;
            const money = this.allMoney;

            return Number(!this.ignoreDeduct && deductAmount ? numSub(money, deductAmount) : money);
        },

        moneyColor() {
            const {ua} = this;
            const defaultColor = '#1fc160';
            if (isMayacash(ua)) {
                return '#FDCB1C';
            }

            if (isOlacred(ua)) {
                return '#ff734b';
            }

            return defaultColor;
        },

        collapseColor() {
            const {ua} = this;
            const defaultColor = '#F7FEFE';
            if (isMayacash(ua)) {
                return '#F5F6FB';
            }

            if (isOlacred(ua)) {
                return '#F5F6FB';
            }

            return defaultColor;
        }
    }
};

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "repay-error" }, [
    _c("img", { attrs: { src: _vm.imgSrc, alt: "" } }),
    _vm._v(" "),
    _c("span", { staticClass: "error-msg" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.repayInfo.detail || _vm.$t("webview.repay.error")) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "btn", on: { click: _vm.handleClick } }, [
      _vm._v("\n        " + _vm._s(_vm.$t("webview.repay.refresh")) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
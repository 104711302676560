<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-3-17 15:35:19 -->
<!-- @desc paynet.vue -->

<template>
    <component
        :is="MethodConfig[payType]"
        :repay-info="repayInfo"
        :account-number="accountNumber"
        :pay-desc="repayInfo.channelName"
        :code-desc="getI18n('paynetCodeDesc')"
        instruction-key="PAYNET_CASH_INSTRUCTIONS"
        class="paynet"
    >
        <div
            slot="extra"
            class="store-wrapper"
        >
            <div class="title">
                {{ getI18n('stores') }}
            </div>

            <div class="store-card">
                <img
                    src="./img/paynetstore.png"
                    alt=""
                >
            </div>
        </div>
    </component>
</template>

<script>
import RepayBase from 'ssr-common/vue/component/repay-base';
import RepayBaseList from 'ssr-common/vue/component/repay-base-list';

const MethodConfig = {
    RepayBase,
    RepayBaseList
};

export default {
    name: 'RepayPaynet',

    title() {
        return this.getI18n('paynetTitle');
    },

    components: {
        RepayBase
    },

    props: {
        repayInfo: {
            type: [Array, Object],
            default: () => ({})
        },
        payType: {
            type: String,
            default: 'RepayBase'
        }
    },

    data() {
        return {
            MethodConfig
        };
    },

    computed: {
        accountNumber() {
            return !Array.isArray(this.repayInfo) ? this.repayInfo.accountNumber : this.repayInfo[0].accountNumber;
        }
    },

    methods: {
        getI18n(text) {
            return this.$t(`webview.repay.${text}`);
        }
    }
};
</script>

<style lang="scss" scoped>
.paynet {
    .store-wrapper {
        margin-top: 0.24rem;

        .title {
            position: relative;
            padding-left: 9px;
            font-size: 14px;
            color: #333;
            line-height: 18px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: calc(50% - 2px);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #333;
            }
        }

        .store-card {
            margin-top: 0.08rem;
            padding: 0.18rem 0.12rem;
            background: #fff;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);
            border-radius: 8px;

            img {
                width: 100%;
            }
        }
    }
}
</style>

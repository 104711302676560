var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channel-unavailable" },
    [
      _c("img", { staticClass: "reload", attrs: { src: _vm.config.errorImg } }),
      _vm._v(" "),
      _c("div", { staticClass: "desc" }, [
        _vm._v("\n        " + _vm._s(_vm.config.errorTxt) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tip" }, [
        _c("img", {
          staticClass: "tip-logo",
          attrs: { src: require("./img/tip.png") },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "tip-text" }, [
          _vm._v("\n            " + _vm._s(_vm.config.errorTip) + "\n        "),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.channelConfig.repayInfo, function (channel, index) {
        return _c(
          "div",
          { key: index },
          [
            channel[0] && channel[0].repayChannelType === 1
              ? _c("div", { staticClass: "channel" }, [
                  _c("span", { staticClass: "icon" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.getI18n("onlineHeader"))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            channel[0] && channel[0].repayChannelType === 2
              ? _c("div", { staticClass: "channel" }, [
                  _c("span", { staticClass: "icon" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.getI18n("offlineHeader"))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(channel, function (item, idx) {
              return _c("div", { key: idx }, [
                _c(
                  "div",
                  { staticClass: "channel-box" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "channel-title",
                        on: {
                          click: function ($event) {
                            return _vm.goPay(item.channelType)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "channel-logo",
                          attrs: { src: item.logoUrl },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "channel-content" }, [
                          _c("div", { staticClass: "channel-txt" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(item.channelName) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          item.repayChannelDeductAmount
                            ? _c("div", { staticClass: "coupon" }, [
                                _c("div", { staticClass: "coupon-left" }, [
                                  _vm._v(
                                    "\n                                - $" +
                                      _vm._s(
                                        _vm._f("numberWithCommas")(
                                          item.repayChannelDeductAmount
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "coupon-right" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.getI18n("deductDesc")) +
                                      "\n                            "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "channel-icon" }),
                      ]
                    ),
                    _vm._v(" "),
                    item.channelType === "STP"
                      ? [
                          _vm.repayInfoMap &&
                          _vm.repayInfoMap.STP &&
                          _vm.repayInfoMap.STP.accountNumber
                            ? _c("div", { staticClass: "channel-stp" }, [
                                _c("div", { staticClass: "stp-title" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.getI18n("clabe")) +
                                      "\n                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "stp-account" }, [
                                  _c("div", { staticClass: "stp-number" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("accountNumber")(
                                            _vm.repayInfoMap.STP.accountNumber
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "stp-copy",
                                      style: { color: _vm.moneyColor },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCopy(
                                            _vm.repayInfoMap.STP.accountNumber
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.getI18n("copy")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      : item.channelType === "PAY_CASH"
                      ? [
                          _c("div", { staticClass: "channel-paycash" }, [
                            _c("img", {
                              staticClass: "paycash-icon",
                              attrs: {
                                src: require("./img/icon.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "paycash-btn",
                                on: { click: _vm.goDetail },
                              },
                              [
                                _c("img", {
                                  staticClass: "location",
                                  attrs: {
                                    src: require("./img/location.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "location-desc" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.getI18n("paycashBtn")) +
                                      "\n                            "
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "foot" }, [
        _vm._v("\n        " + _vm._s(_vm.getI18n("footTip")) + "\n    "),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "repay-base" },
    [
      _c("div", { staticClass: "money-card" }, [
        _c("span", { staticClass: "money-title" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.getI18n("payTitle")) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("span", [
          _c(
            "span",
            { staticClass: "money-discount", style: { color: _vm.moneyColor } },
            [
              _vm._v(
                "\n                $ " +
                  _vm._s(_vm._f("numberWithCommasFixed2")(_vm.discountMoney)) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showAllMoney
            ? _c("del", { staticClass: "money-all" }, [
                _vm._v(
                  "\n                $ " +
                    _vm._s(_vm._f("numberWithCommasFixed2")(_vm.allMoney)) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "account-card" }, [
        _c("div", { staticClass: "number-code" }, [
          _c("span", [_vm._v(_vm._s(_vm.getI18n("accountCode")))]),
          _vm._v(" "),
          _c("div", { staticClass: "time-desc" }, [
            _vm._v(
              "\n                " + _vm._s(_vm.timeDesc) + "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("canvas", { attrs: { id: "code" } }),
          _vm._v(" "),
          _c("div", { staticClass: "account-code" }, [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm._f("accountNumber")(_vm.accountNumber, _vm.splitSign)
                ) +
                "\n            "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("extra"),
      _vm._v(" "),
      _vm.instructionKey
        ? _c(
            "div",
            { staticClass: "instruction" },
            [
              _c("yqg-md-preview", {
                attrs: { "md-text": _vm.instruction.content || "" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
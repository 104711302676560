<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-3-18 13:36:11 -->
<!-- @desc error.vue -->

<template>
    <div class="repay-error">
        <img
            :src="imgSrc"
            alt=""
        >
        <span class="error-msg">
            {{ repayInfo.detail || $t('webview.repay.error') }}
        </span>
        <div
            class="btn"
            @click="handleClick"
        >
            {{ $t('webview.repay.refresh') }}
        </div>
    </div>
</template>

<script>
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import completed from './img/completed.png';
import reload from './img/reload.png';

export default {
    name: 'RepayError',

    props: {
        repayInfo: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        imgSrc() {
            if (this.repayInfo.code === 1011) {
                return completed;
            }

            return reload;
        }
    },

    methods: {
        handleClick() {
            if (this.repayInfo.code === 1011) {
                WebviewInterface.openHomePage();

                return;
            }

            window.location.reload();
        }
    }
};
</script>

<style lang="scss" scoped>
    .repay-error {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        line-height: 25px;
        text-align: center;

        img {
            margin-top: 0.5rem;
            width: 0.7rem;
        }

        .error-msg {
            margin-top: 0.2rem;
            color: #333;
        }

        .btn {
            margin-top: 0.56rem;
            padding: 0.2rem;
            background: #ff734b;
            border-radius: 8px;
            color: #fff;
            width: 100%;
            text-align: center;
        }
    }
</style>

<!-- @author wuhuiwang -->
<!-- @email wuhuiwang@yangqianguan.com -->
<!-- @date 2022-10-17 19:27:10 -->
<!-- @desc barCode.vue -->

<template>
    <canvas
        id="code"
        class="code"
    />
</template>

<script>
import JsBarcode from 'jsbarcode';

export default {
    name: 'BarCode',

    props: {
        codeValue: {
            type: String
        }
    },

    watch: {
        codeValue: {
            handler(val) {
                if (!this.$isServer) {
                    this.$nextTick(() => {
                        if (val) {
                            JsBarcode('#code', val, {
                                displayValue: false,
                                margin: 0
                            });
                        }
                    });
                }
            },
            immediate: true
        }
    }
};
</script>

<style>
    .code {
        height: 0.78rem;
        width: 100%;
        padding: 0 0.3rem;
    }
</style>

<!--
 * @Author: zhaoyang
 * @Date: 2022-09-26 15:33:55
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-10-11 11:46:35
-->

<template>
    <div class="channel-unavailable">
        <img
            :src="config.errorImg"
            class="reload"
        >
        <div class="desc">
            {{ config.errorTxt }}
        </div>
        <div class="tip">
            <img
                class="tip-logo"
                src="./img/tip.png"
            >
            <span class="tip-text">
                {{ config.errorTip }}
            </span>
        </div>

        <div
            v-for="(channel, index) in channelConfig.repayInfo"
            :key="index"
        >
            <div
                v-if="channel[0] && channel[0].repayChannelType === 1"
                class="channel"
            >
                <span class="icon" />
                <span class="title">{{ getI18n('onlineHeader') }}</span>
            </div>
            <div
                v-if="channel[0] && channel[0].repayChannelType === 2"
                class="channel"
            >
                <span class="icon" />
                <span class="title">{{ getI18n('offlineHeader') }}</span>
            </div>
            <div
                v-for="(item, idx) in channel"
                :key="idx"
            >
                <div class="channel-box">
                    <div
                        class="channel-title"
                        @click="goPay(item.channelType)"
                    >
                        <img
                            class="channel-logo"
                            :src="item.logoUrl"
                        >
                        <div class="channel-content">
                            <div class="channel-txt">
                                {{ item.channelName }}
                            </div>
                            <div
                                v-if="item.repayChannelDeductAmount"
                                class="coupon"
                            >
                                <div class="coupon-left">
                                    - ${{ item.repayChannelDeductAmount | numberWithCommas }}
                                </div>
                                <div class="coupon-right">
                                    {{ getI18n('deductDesc') }}
                                </div>
                            </div>
                        </div>
                        <div class="channel-icon" />
                    </div>
                    <template v-if="item.channelType === 'STP'">
                        <div
                            v-if="repayInfoMap
                                && repayInfoMap.STP
                                && repayInfoMap.STP.accountNumber"
                            class="channel-stp"
                        >
                            <div class="stp-title">
                                {{ getI18n('clabe') }}
                            </div>
                            <div class="stp-account">
                                <div class="stp-number">
                                    {{ repayInfoMap.STP.accountNumber | accountNumber }}
                                </div>
                                <div
                                    class="stp-copy"
                                    :style="{color: moneyColor}"
                                    @click="handleCopy(repayInfoMap.STP.accountNumber)"
                                >
                                    {{ getI18n('copy') }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="item.channelType === 'PAY_CASH'">
                        <div class="channel-paycash">
                            <img
                                src="./img/icon.png"
                                alt=""
                                class="paycash-icon"
                            >
                            <div
                                class="paycash-btn"
                                @click="goDetail"
                            >
                                <img
                                    src="./img/location.png"
                                    alt=""
                                    class="location"
                                >
                                <span class="location-desc">
                                    {{ getI18n('paycashBtn') }}
                                </span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="foot">
            {{ getI18n('footTip') }}
        </div>
    </div>
</template>

<script>
import Repay from 'ssr-common/resource/repay';
import StaticText from 'ssr-common/resource/static-text';
import {numberWithCommas} from 'ssr-common/util/string';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import payMoney from '../repay-base/mixin/pay-money';

export default {
    name: 'ChannelUnavailable',

    mixins: [prefetch('channelConfig'), payMoney],

    inject: ['repayInfoMap'],

    title() {
        return this.config?.pageTitle;
    },

    filters: {
        accountNumber(number) {
            return number?.replace(/(.{4})/g, '$1 ');
        },

        numberWithCommas
    },

    props: {
        channelList: {
            type: Array,
            default: () => ([])
        }
    },

    computed: {
        config() {
            const {channelConfig, $route: {params: {method}}} = this;

            return JSON.parse(channelConfig?.content ? channelConfig?.content : '{}')?.[method] ?? {};
        }
    },

    methods: {
        async initFetch({header}) {
            const {channelList} = this;
            const [pageConfig, repayConfig] = await Promise.all([
                this.fetchPageConfig(),
                this.fetchRepayInfo(header)
            ]);

            const filteredRepayConfig = repayConfig?.repayInfo?.map(item => item?.filter(info => !channelList.includes(info.channelType)));

            return {
                ...pageConfig,
                repayInfo: filteredRepayConfig,
                isOldVersion: repayConfig.isOldVersion
            };
        },

        getPlatformType() {
            if (this.isIOS) return 'iOS';
            if (this.isAndroid) return 'Android';

            return null;
        },

        async fetchRepayInfo(header) {
            const PlatformType = this.getPlatformType();
            const {method} = this.$route.params;
            const {data: {body}} = await Repay.getRepayVirtualAccount({
                headers: {
                    ...header,
                    PlatformType
                }
            }).catch(() => ({data: {body: []}}));
            const onLineChannel = [];
            const offlineChannel = [];
            let isOldVersion = false;
            const allChannel = [];
            body.forEach(item => {
                if (method === item.channelType) return;
                if (item.repayChannelType === 1) {
                    onLineChannel.push(item);
                } else if (item.repayChannelType === 2) {
                    offlineChannel.push(item);
                } else {
                    isOldVersion = true;
                    allChannel.push(item);
                }
            });
            const repayInfo = isOldVersion ? [allChannel] : [onLineChannel, offlineChannel];

            return {repayInfo, isOldVersion};
        },

        async fetchPageConfig() {
            const {data: {body: {content}}} = await StaticText.getByKey({
                params: {key: 'MEX_CHANNEL_CONFIG'}
            }).catch(() => ({data: {body: {content: ''}}}));

            return {content};
        },

        getI18n(text, params) {
            return this.$t(`webview.repay.${text}`, params);
        },

        handleCopy(accountNumber) {
            WebviewInterface.copyToClipboard(accountNumber.toString());
            this.$toast.success(this.getI18n('copySuccess'));
        },

        goPay(channelType) {
            const {query} = this.$route;

            this.$router.replace({
                params: {
                    method: channelType
                },
                query: {
                    ...query,
                    channelType
                }
            });
        },

        goDetail() {
            WebviewInterface.openWebview('https://www.paycashglobal.com/buscador.php');
        }
    }
};
</script>

<style lang="scss" scoped>
$tipColor: #1fc160;
$couponColor: #ff734b;

div.channel-unavailable {
    box-sizing: border-box;
    padding: 0.1rem 0.15rem !important;
    font-size: 14px;
    line-height: 18px;
    font-family: HelveticaBold, Helvetica;
    color: #666;

    .reload {
        width: 65%;
        margin: 0 auto;
    }

    .desc {
        width: 65%;
        margin: 0.06rem auto 0.12rem;
        font-weight: bold;
        text-align: center;
    }

    .tip {
        display: flex;
        box-sizing: border-box;
        padding: 0.08rem 0.15rem 0.08rem 0.09rem;
        background-color: $tipColor;
        border-radius: 8px;
        position: relative;

        .tip-logo {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.1rem;
        }

        .tip-text {
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            line-height: 16px;
        }

        &::before {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $tipColor;
            position: absolute;
            top: -6px;
            left: 50%;
            margin-left: -4px;
        }
    }

    .channel {
        margin-top: 0.2rem;
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;

        .icon {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #666;
            margin-right: 0.05rem;
        }

        .title {
            color: #333;
        }
    }

    .channel-box {
        margin-top: 0.2rem;
        box-sizing: border-box;
        background: #fff;
        box-shadow: 0 2px 10px 0 rgba(37, 51, 56, 20%);
        border-radius: 8px;
        padding: 0.1rem 0 0;

        &:first-child {
            margin-top: 0.1rem;
        }

        .channel-title {
            box-sizing: border-box;
            padding: 0.1rem 0.2rem 0.2rem;
            border-bottom: 1px solid #ebebeb;
            display: flex;
            align-items: center;

            .channel-logo {
                width: 0.24rem;
                margin-right: 0.07rem;
            }

            .channel-content {
                flex: 1;

                .channel-txt {
                    font-family: Helvetica;
                    color: #333;
                }

                .coupon {
                    color: $couponColor;
                    margin-top: 0.02rem;
                    border: 1px solid $couponColor;
                    border-radius: 4px;
                    display: inline-flex;
                    height: 18px;

                    .coupon-left {
                        box-sizing: border-box;
                        padding: 0.04rem 0.05rem 0.02rem;
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 1;
                        border-right: 1px solid $couponColor;
                        display: inline-flex;
                        align-items: center;
                    }

                    .coupon-right {
                        box-sizing: border-box;
                        padding: 0.02rem 0.05rem;
                        font-size: 10px;
                        line-height: 1;
                        font-family: Helvetica;
                        color: $couponColor;
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }

            .channel-icon {
                width: 8px;
                height: 8px;
                border-top: 2px solid #ccc;
                border-right: 2px solid #ccc;
                transform: rotate(45deg);
            }
        }

        .channel-stp {
            box-sizing: border-box;
            padding: 0.27rem 0.2rem 0.2rem;

            .stp-title {
                color: #999;
                font-family: Helvetica;
            }

            .stp-account {
                display: flex;
                justify-content: space-between;
                margin-top: 0.1rem;

                .stp-number {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                    line-height: 22px;
                }

                .stp-copy {
                    font-weight: bold;
                }
            }
        }

        .channel-paycash {
            box-sizing: border-box;
            padding: 0.05rem 0.2rem 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            .paycash-icon {
                width: 100%;
                margin-bottom: 0.13rem;
            }

            .paycash-btn {
                border-radius: 16px/50%;
                border: 1px solid #1fc160;
                display: inline-flex;
                box-sizing: border-box;
                padding: 0.1rem 0.15rem;
                margin-bottom: 0.2rem;
                align-items: center;

                .location {
                    width: 0.1rem;
                    height: 0.12rem;
                    margin-right: 0.05rem;
                }

                .location-desc {
                    font-size: 14px;
                    font-family: Helvetica;
                    color: #1fc160;
                    line-height: 14px;
                }
            }
        }
    }

    .foot {
        font-size: 12px;
        font-family: Helvetica;
        color: #999;
        line-height: 16px;
        text-align: center;
        box-sizing: border-box;
        padding: 0.2rem 0.1rem 0.33rem;
    }
}
</style>

<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-3-17 18:03:48 -->
<!-- @desc stp.vue -->

<template>
    <div class="repay-stp">
        <div class="money-card">
            <span class="money-title">
                {{ getI18n('payTitle') }}
            </span>
            <span>
                <span
                    class="money-discount"
                    :style="{color: moneyColor}"
                >
                    $ {{ discountMoney | numberWithCommasFixed2 }}
                </span>
                <del
                    v-if="showAllMoney"
                    class="money-all"
                >
                    $ {{ allMoney | numberWithCommasFixed2 }}
                </del>
            </span>
        </div>

        <div class="card account-card">
            <div class="account-number">
                <h3>
                    {{ getI18n('clabe') }}
                </h3>
                <div class="number-wrapper">
                    <span class="number">{{ repayInfo.accountNumber | accountNumber }}</span>
                    <span
                        class="copy"
                        :style="{color: moneyColor}"
                        @click="handleCopy"
                    >
                        {{ getI18n('copy') }}
                    </span>
                </div>
            </div>

            <div class="line" />

            <div class="bank-info">
                <div class="mb8">
                    <span>{{ getI18n('bankName') }}</span>
                    <span>Arcus</span>
                </div>
                <div>
                    <span>{{ getI18n('accountName') }}</span>
                    <span>{{ getI18n('bankAppName') }}</span>
                </div>
            </div>
        </div>

        <div class="arcusDesc">
            {{ getI18n('arcusDesc') }}
        </div>
    </div>
</template>

<script>
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import payMoney from '../repay-base/mixin/pay-money';

export default {
    name: 'RepayArcus',

    title() {
        return this.getI18n('arcusTitle');
    },

    filters: {
        accountNumber(number) {
            return number?.replace(/(.{4})/g, '$1 ');
        }
    },

    mixins: [payMoney],

    props: {
        repayInfo: {
            type: Object,
            default: () => ({})
        }
    },

    methods: {
        getI18n(text) {
            return this.$t(`webview.repay.${text}`);
        },

        handleCopy() {
            WebviewInterface.copyToClipboard(this.repayInfo.accountNumber);
            this.$toast.success(this.getI18n('copySuccess'));
        }
    }
};
</script>

<style lang="scss" scoped>
.repay-stp {
    font-size: 14px;
    color: #333;
    line-height: 18px;

    .money-card {
        margin-top: 0.1rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 0.2rem 0.2rem 0.12rem;
        background-color: #fff;
        box-shadow: 0 2px 10px 0 rgba(37, 51, 56, 20%);
        border-radius: 8px;

        .money-title {
            font-size: 14px;
            font-family: Helvetica;
            color: #999;
            line-height: 18px;
            margin-bottom: 0.05rem;
        }

        .money-discount {
            font-size: 18px;
            font-family: "Helvetica-Bold", Helvetica;
            font-weight: bold;
            line-height: 25px;
        }

        .money-all {
            font-size: 12px;
            font-family: Helvetica;
            color: #999;
            line-height: 16px;
            margin-left: 0.1rem;
        }
    }

    .card {
        background: #fff;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);
        border-radius: 8px;

        h3 {
            font-size: 14px;
            color: #999;
            line-height: 18px;
        }
    }

    .amount-card {
        padding: 0.2rem;

        .amount {
            margin-top: 0.05rem;
            font-size: 14px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #333;
            line-height: 18px;
        }
    }

    .account-card {
        margin-top: 0.1rem;

        .line {
            width: 94%;
            height: 1px;
            background: #ebebeb;
            margin: 0 auto;
        }

        .account-number {
            padding: 0.27rem 0.2rem 0.24rem;

            .number-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0.07rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;

                .number {
                    font-size: 18px;
                    color: #333;
                    line-height: 25px;
                }

                .copy {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .bank-info {
            display: flex;
            flex-direction: column;
            padding: 0.18rem 0.2rem;

            .mb8 {
                margin-bottom: 0.08rem;
            }

            div > span:first-child {
                color: #999;
            }
        }
    }

    .arcusDesc {
        box-sizing: border-box;
        padding: 0 0.1rem;
        margin-top: 0.2rem;
        font-size: 12px;
        color: #999;
        line-height: 16px;
        text-align: center;
    }
}
</style>

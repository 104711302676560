var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "repay-stp" }, [
    _c("div", { staticClass: "money-card" }, [
      _c("span", { staticClass: "money-title" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.getI18n("payTitle")) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("span", [
        _c(
          "span",
          { staticClass: "money-discount", style: { color: _vm.moneyColor } },
          [
            _vm._v(
              "\n                $ " +
                _vm._s(_vm._f("numberWithCommasFixed2")(_vm.discountMoney)) +
                "\n            "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.showAllMoney
          ? _c("del", { staticClass: "money-all" }, [
              _vm._v(
                "\n                $ " +
                  _vm._s(_vm._f("numberWithCommasFixed2")(_vm.allMoney)) +
                  "\n            "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card account-card" }, [
      _c("div", { staticClass: "account-number" }, [
        _c("h3", [
          _vm._v(
            "\n                " +
              _vm._s(_vm.getI18n("clabe")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "number-wrapper" }, [
          _c("span", { staticClass: "number" }, [
            _vm._v(
              _vm._s(_vm._f("accountNumber")(_vm.repayInfo.accountNumber))
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "copy",
              style: { color: _vm.moneyColor },
              on: { click: _vm.handleCopy },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getI18n("copy")) +
                  "\n                "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "bank-info" }, [
        _c("div", { staticClass: "mb8" }, [
          _c("span", [_vm._v(_vm._s(_vm.getI18n("bankName")))]),
          _vm._v(" "),
          _c("span", [_vm._v("Arcus")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", [_vm._v(_vm._s(_vm.getI18n("accountName")))]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.getI18n("bankAppName")))]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "arcusDesc" }, [
      _vm._v("\n        " + _vm._s(_vm.getI18n("arcusDesc")) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/*
 * @Author: zhaoyang
 * @Date: 2022-09-27 17:06:03
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-09-27 19:37:56
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from '../constant/config';

const api = {
    getHost: getEurekaHost,

    getRepayVirtualAccount: {
        url: '/api/v3/cashloan/getRepayVirtualAccount',
        method: 'get'
    },

    generalConfig: {
        url: '/api/generalConfig',
        method: 'get'
    }
};

export default Resource.create(api);

<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-3-16 14:41:39 -->
<!-- @desc base.vue -->

<template>
    <div class="repay-base">
        <div class="money-card">
            <span class="money-title">
                {{ getI18n('payTitle') }}
            </span>
            <span>
                <span
                    class="money-discount"
                    :style="{color: moneyColor}"
                >
                    $ {{ discountMoney | numberWithCommasFixed2 }}
                </span>
                <del
                    v-if="showAllMoney"
                    class="money-all"
                >
                    $ {{ allMoney | numberWithCommasFixed2 }}
                </del>
            </span>
        </div>
        <div class="account-card">
            <div class="number-code">
                <div
                    v-if="repayInfo.length > 0"
                    class="collapse-wrap"
                >
                    <div
                        class="collapse-bar"
                        :style="{background: moneyColor !== '#1fc160' ? '#E6E9F0' : '#E3F2F2'}"
                    />
                    <div class="collapse">
                        <van-collapse
                            v-model="activeNames"
                            :accordion="true"
                            :border="false"
                            @change="changeCollapse"
                        >
                            <van-collapse-item
                                v-for="(item, index) in repayInfo"
                                :key="index"
                                :name="item.accountNumber"
                                :class="{
                                    'noBorderTop': index === currentIndex + 1 && item.showCollapseItem,
                                }"
                            >
                                <template #title>
                                    <div class="title-left">
                                        <div
                                            class="title-border"
                                            :style="{'border-color': `transparent transparent transparent ${moneyColor}`}"
                                        />
                                        <div class="title-text">
                                            {{ getI18n('shouldPayCash', [index+1]) }}
                                        </div>
                                        <div
                                            class="title-cash"
                                            :class="{
                                                'title-cash-active': item.showCollapseItem && currentIndex === index
                                            }"
                                        >
                                            $ {{ item.repayCodeAmount | numberWithCommasFixed2 }}
                                        </div>
                                    </div>
                                </template>
                                <div class="collapse-body">
                                    <barCode :code-value="codeValue" />
                                    <div class="account-code">
                                        {{ item.accountNumber | accountNumber(splitSign) }}
                                    </div>
                                    <div
                                        v-if="utcTime"
                                        class="desc-wrap"
                                    >
                                        <div
                                            class="utc-time"
                                            :style="{color: moneyColor}"
                                        >
                                            {{ utcTime + ' - 24:00' }}
                                        </div>
                                        <div class="time-desc">
                                            {{ getI18n('timeDescList') }}
                                        </div>
                                    </div>
                                </div>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
            </div>
        </div>

        <slot name="extra" />

        <div
            v-if="instructionKey"
            class="instruction"
        >
            <yqg-md-preview :md-text="instruction.content || ''" />
        </div>

        <ec-overlay
            :visible="modalVisible"
            class="paycash-modal modal"
        >
            <div class="wrapper">
                <div class="content-wrapper">
                    <img src="./img/bill.png">
                    <div class="multi-title">
                        {{ getI18n('multiModalTitle') }}
                    </div>
                    <div
                        class="multi-text"
                        v-html="getI18n('multiModalText', [moneyColor, '10000 pesos', moneyColor, `${repayInfo.length || 0} códigos`])"
                    />
                    <div
                        class="multi-btn"
                        :style="{background: moneyColor}"
                        @click="closeModal"
                    >
                        {{ getI18n('multiModalBtn') }}
                    </div>
                </div>
                <img
                    class="close"
                    src="./img/close.png"
                    alt=""
                    @click="closeModal"
                >
            </div>
        </ec-overlay>
    </div>
</template>

<script>
import {Collapse, CollapseItem} from 'vant';

import StaticText from 'ssr-common/resource/static-text';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import EcOverlay from '../ec-overlay/ec-overlay';
import payMoney from '../repay-base/mixin/pay-money';
import barCode from './barCode';

export default {
    name: 'RepayBase',

    filters: {
        accountNumber(number, splitSign) {
            return number?.replace(/(.{4})/g, `$1${splitSign}`);
        }
    },

    components: {
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        barCode,
        EcOverlay
    },

    mixins: [prefetch('instruction'), payMoney],

    props: {
        repayInfo: {
            type: Array,
            default: () => []
        },

        splitSign: {
            type: String,
            default: ' '
        },

        utcTime: {
            type: String,
            default: ''
        },

        instructionKey: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            activeNames: '',
            currentIndex: '',
            currentItem: {},
            modalVisible: false
        };
    },

    computed: {
        codeValue() {
            return this.currentItem.accountNumber;
        }
    },

    watch: {
        repayInfo: {
            handler(val) {
                this.modalVisible = Array.isArray(val) && val.length > 1;
            },
            deep: true,
            immediate: true
        }
    },

    mounted() {
        this.activeNames = this.repayInfo[0]?.accountNumber;
        this.changeCollapse(this.activeNames);
        const collapseBtnList = document.getElementsByClassName('van-cell__right-icon');
        const collapseCellList = document.getElementsByClassName('van-cell');
        this.$nextTick(() => {
            if (collapseBtnList) {
                collapseBtnList.forEach(doc => {
                    doc.style.background = this.moneyColor;
                });
            }

            if (collapseCellList) {
                collapseCellList.forEach(doc => {
                    doc.style.background = this.collapseColor;
                });
            }
        });
    },

    methods: {
        closeModal() {
            this.modalVisible = false;
            this.$emit('getInstrutionUrl', this.instruction?.url);
        },

        getI18n(text, param) {
            return this.$t(`webview.repay.${text}`, param);
        },

        async initFetch() {
            const {data: {body: {content}}} = await StaticText.getByKey({
                params: {key: this.instructionKey}
            }).catch(() => ({data: {body: {content: ''}}}));
            const hrefIndex = content.indexOf('href="');
            const arrowIndex = content.substring(hrefIndex).indexOf('">');
            const url = content.substring(hrefIndex + 6, hrefIndex + arrowIndex);

            return {content, url};
        },

        handleCopy() {
            WebviewInterface.copyToClipboard(this.accountNumber);
            this.$toast.success(this.getI18n('copySuccess'));
        },

        async changeCollapse(val) {
            if (!val) {
                this.repayInfo.forEach(item => {
                    item.showCollapseItem = false;
                });

                return;
            }

            this.repayInfo.forEach((item, index) => {
                if (item.accountNumber === val) {
                    item.showCollapseItem = true;
                    this.currentItem = item;
                    this.currentIndex = index;
                } else {
                    item.showCollapseItem = false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.repay-base {
    .header {
        display: flex;
        align-items: center;
    }

    .money-card {
        margin-top: 0.18rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 0 0.1rem;

        .money-title {
            font-size: 0.14rem;
            font-family: Helvetica;
            color: #999;
            line-height: 0.18rem;
            margin-bottom: 0.05rem;
        }

        .money-discount {
            font-size: 0.26rem;
            font-family: "Helvetica-Bold", Helvetica;
            font-weight: bold;
            line-height: 0.36rem;
        }

        .money-all {
            font-size: 0.12rem;
            font-family: Helvetica;
            color: #999;
            line-height: 0.16rem;
            margin-left: 0.1rem;
        }
    }

    .account-card {
        margin-top: 0.2rem;
        font-size: 0.12rem;
        color: #999;
        line-height: 0.16rem;

        .number-code {
            display: flex;
            flex-direction: column;
            align-items: center;

            .account-code {
                font-size: 0.18rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #333;
                line-height: 0.25rem;
                text-shadow: 0 0 0.24rem rgba(23, 43, 34, 10%);
                text-align: center;
                padding-bottom: 0.08rem;
            }

            .desc-wrap {
                border-top: 1px dashed #e3ecec;
                padding: 0.11rem 0 0;
                text-align: center;
                font-size: 0.12rem;
                font-family: Helvetica;
                line-height: 0.16rem;
                text-shadow: 0 0 0.24rem rgba(23, 43, 34, 10%);

                .utc-time {
                    color: #1fc160;
                }

                .time-desc {
                    color: #999;
                }
            }

            .collapse-wrap {
                width: 100%;

                .collapse-bar {
                    width: 100%;
                    height: 0.1rem;
                    background: #e3f2f2;
                    border-radius: 6px;
                }

                ::v-deep .van-cell {
                    height: 0.72rem;
                    padding: 0 0.15rem;
                    background: #f2fefe;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &::after {
                        border-bottom: 1px dashed #e3ecec;
                    }

                    &.van-collapse-item__title--expanded {
                        background: #fff !important;
                    }
                }

                ::v-deep .van-collapse-item__wrapper {
                    border-radius: 0 0 0.08rem 0.08rem;
                }

                ::v-deep .van-collapse-item__content {
                    padding: 0.15rem;
                }

                ::v-deep .van-collapse-item--border::after {
                    left: 0;
                    right: 0;
                }

                ::v-deep .van-cell__right-icon {
                    width: 0.72rem;
                    height: 0.3rem;
                    margin-right: 0.05rem;
                    display: flex;
                    align-items: center;
                    border-radius: 0.15rem;
                    color: #fff;
                    font-size: 0.14rem;
                    font-weight: bold;
                    text-shadow: 0 0 0.24rem rgba(23, 43, 34, 10%);
                }

                ::v-deep .van-icon-arrow {
                    &::before {
                        position: absolute;
                        left: 0.49rem;
                    }

                    &::after {
                        content: "Pago";
                        position: absolute;
                        left: 0.11rem;
                        height: 0.18rem;
                        font-size: 0.14rem;
                        font-family: Helvetica-Bold, Helvetica;
                        font-weight: bold;
                        color: #fff;
                        line-height: 0.18rem;
                    }
                }

                .collapse {
                    margin: -0.05rem 0.05rem 0;
                    box-shadow: 0 0 0.1rem 0 rgba(37, 51, 56, 20%);
                    border-radius: 0 0 0.08rem 0.08rem;
                    overflow: hidden;

                    .title-left {
                        position: relative;
                        padding-left: 0.05rem;

                        .title-border {
                            position: absolute;
                            left: -0.15rem;
                            width: 0;
                            height: 0.16rem;
                            border-width: 3px;
                            border-style: solid;
                            border-color: transparent transparent transparent #1fc160;
                        }

                        .title-text {
                            font-size: 0.12rem;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #999;
                            line-height: 0.16rem;
                            text-shadow: 0 0 0.24rem rgba(23, 43, 34, 10%);
                        }

                        .title-cash {
                            font-size: 0.2rem;
                            font-family: Helvetica-Bold, Helvetica;
                            font-weight: bold;
                            line-height: 0.3rem;
                            text-shadow: 0 0 0.24rem rgba(23, 43, 34, 10%);
                            color: #666;

                            &.title-cash-active {
                                color: #333;
                            }
                        }
                    }

                    .noBorderTop {
                        &.van-collapse-item--border::after {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }

    .instruction {
        margin-top: 0.28rem;
        padding: 0 0.05rem;

        ::v-deep ol {
            list-style-type: decimal;
        }
    }
}

.modal {
    .wrapper {
        box-sizing: border-box;
        position: relative;
        padding: 0.2rem;
        width: 3.05rem;
        background: #fff;
        border-radius: 8px;

        .content-wrapper {
            img {
                width: 0.44rem;
                height: 0.46rem;
                margin-top: -0.11rem;
            }

            .multi-title {
                font-size: 0.16rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #333;
                line-height: 0.22rem;
                margin-top: 0.09rem;
            }

            .multi-text {
                margin-top: 0.15rem;
                font-size: 0.14rem;
                font-family: Helvetica;
                color: #666;
                line-height: 0.18rem;
            }

            .multi-btn {
                width: 100%;
                height: 0.48rem;
                margin-top: 0.3rem;
                border-radius: 0.06rem;
                font-size: 0.14rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .close {
            position: absolute;
            right: 0.2rem;
            top: 0.2rem;
            width: 0.2rem;
            height: 0.2rem;
        }
    }
}
</style>

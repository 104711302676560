/*
 * @Author: chengyuzhang
 * @Date: 2021-03-16 18:47:37
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-11-24 11:15:38
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    getRepayVirtualAccount: {
        url: '/api/v2/cashloan/getRepayVirtualAccount',
        method: 'get'
    },

    getRepaymentAccountByChannel: {
        url: '/api/v2/cashloan/getRepaymentAccountByChannel',
        method: 'get'
    },

    getRepaymentAccountByChannelV3: {
        url: '/api/cashloan/mexico/getRepaymentAccountByChannel',
        method: 'post'
    }
};

export default Resource.create(api);

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.MethodConfig[_vm.payType], {
    tag: "component",
    attrs: {
      "repay-info": _vm.repayInfo,
      "account-number": _vm.accountNumber,
      "pay-desc": _vm.repayInfo.channelName,
      "code-desc": _vm.getI18n("oxxoCodeDesc"),
      "instruction-key": "OXXO_CASH_INSTRUCTIONS",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
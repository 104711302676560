var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        _vm.MethodConfig[_vm.payType],
        {
          tag: "component",
          staticClass: "paycash",
          attrs: {
            "repay-info": _vm.repayInfo,
            "utc-time": _vm.utcTime,
            "account-number": _vm.accountNumber,
            "time-desc": _vm.getI18n("timeDesc", [_vm.utcTime]),
            "instruction-key": _vm.paycashInstruction,
          },
          on: { getInstrutionUrl: _vm.getInstrutionUrl },
        },
        [
          _c(
            "div",
            {
              staticClass: "store-wrapper",
              attrs: { slot: "extra" },
              slot: "extra",
            },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.getI18n("paycashStores")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "store-card" },
                [
                  _c("horseman", { ref: "horseman", attrs: { duration: 12 } }, [
                    _c("div", { staticClass: "source-wrap" }, [
                      _c("img", {
                        staticClass: "source",
                        attrs: { src: require("./img/logo.png") },
                        on: { load: _vm.onImgLoad },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "horsebtn", on: { click: _vm.gotolink } },
                    [
                      _c("img", {
                        attrs: { src: require("./img/location.png") },
                      }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getI18n("payPoint")) +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: zhaoyang
 * @Date: 2022-09-07 18:59:17
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-09-29 18:17:23
-->

<template>
    <div>
        <component
            :is="MethodConfig[payType]"
            :repay-info="repayInfo"
            :utc-time="utcTime"
            :account-number="accountNumber"
            :time-desc="getI18n('timeDesc', [utcTime])"
            :instruction-key="paycashInstruction"
            class="paycash"
            @getInstrutionUrl="getInstrutionUrl"
        >
            <div
                slot="extra"
                class="store-wrapper"
            >
                <div class="title">
                    {{ getI18n('paycashStores') }}
                </div>

                <div class="store-card">
                    <horseman
                        ref="horseman"
                        :duration="12"
                    >
                        <div class="source-wrap">
                            <img
                                class="source"
                                src="./img/logo.png"
                                @load="onImgLoad"
                            >
                        </div>
                    </horseman>
                    <div
                        class="horsebtn"
                        @click="gotolink"
                    >
                        <img src="./img/location.png">
                        {{ getI18n('payPoint') }}
                    </div>
                </div>
            </div>
        </component>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Horseman from '../horseman';
import RepayBase from '../repay-base';
import RepayBaseList from '../repay-base-list';
import payMoney from '../repay-base/mixin/pay-money';

const MethodConfig = {
    RepayBase,
    RepayBaseList
};

export default {
    name: 'RepayPaynet',

    title() {
        return this.getI18n('paycashTitle');
    },

    components: {
        RepayBase,
        Horseman
    },

    mixins: [payMoney],

    inject: ['paycashInstruction'],

    props: {
        repayInfo: {
            type: [Array, Object],
            default: () => ({})
        },
        payType: {
            type: String,
            default: 'RepayBase'
        }
    },

    data() {
        return {
            MethodConfig,
            utcTime: null
        };
    },

    computed: {
        accountNumber() {
            return !Array.isArray(this.repayInfo) ? this.repayInfo.accountNumber : this.repayInfo[0]?.accountNumber;
        }
    },

    mounted() {
        dayjs.extend(utc);
        this.utcTime = dayjs.utc().format('DD/MM/YYYY');
    },

    methods: {
        getI18n(text, params) {
            return this.$t(`webview.repay.${text}`, params);
        },

        onImgLoad() {
            this.$refs.horseman.start();
        },

        getInstrutionUrl(url) {
            this.locationUrl = url;
        },

        gotolink() {
            const url = this.$store.state.instruction?.responseData?.url;
            if (!this.locationUrl && !url) return;
            window.location.href = this.locationUrl || url;
        }
    }
};
</script>

<style lang="scss" scoped>
.paycash {
    .store-wrapper {
        margin-top: 0.24rem;
        padding: 0 0.05rem;

        .title {
            position: relative;
            padding-left: 9px;
            font-size: 14px;
            color: #333;
            line-height: 18px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: calc(50% - 2px);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #333;
            }
        }

        .store-card {
            margin-top: 0.08rem;
            padding: 0.18rem 0;
            background: #fff;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);
            border-radius: 8px;
            overflow: hidden;

            .source-wrap {
                box-sizing: border-box;
                padding-left: 0.12rem;
                padding-right: 0.12rem;

                .source {
                    max-width: unset;
                    height: 0.55rem;
                    width: auto;
                }
            }

            .horsebtn {
                margin: 0 auto;
                margin-top: 0.12rem;
                width: 1.49rem;
                height: 0.32rem;
                background: rgba(216, 216, 216, 0%);
                border-radius: 0.16rem;
                border: 1px solid #a3acbc;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.14rem;
                font-family: Helvetica;
                color: #a3acbc;

                img {
                    width: 0.12rem;
                    height: 0.16rem;
                    margin-right: 0.05rem;
                }
            }
        }
    }
}
</style>

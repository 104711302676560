<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-3-16 14:18:31 -->
<!-- @desc oxxo.vue -->

<template>
    <component
        :is="MethodConfig[payType]"
        :repay-info="repayInfo"
        :account-number="accountNumber"
        :pay-desc="repayInfo.channelName"
        :code-desc="getI18n('oxxoCodeDesc')"
        instruction-key="OXXO_CASH_INSTRUCTIONS"
    />
</template>

<script>
import RepayBase from 'ssr-common/vue/component/repay-base';
import RepayBaseList from 'ssr-common/vue/component/repay-base-list';

const MethodConfig = {
    RepayBase,
    RepayBaseList
};

export default {
    name: 'RepayOxxo',

    title() {
        return this.getI18n('oxxoTitle');
    },

    components: {
        RepayBase
    },

    props: {
        repayInfo: {
            type: [Array, Object],
            default: () => ({}),
            required: true
        },
        payType: {
            type: String,
            default: 'RepayBase'
        }
    },

    data() {
        return {
            MethodConfig
        };
    },

    computed: {
        accountNumber() {
            return !Array.isArray(this.repayInfo) ? this.repayInfo.accountNumber : this.repayInfo[0].accountNumber;
        }
    },

    methods: {
        getI18n(text) {
            return this.$t(`webview.repay.${text}`);
        }
    }
};
</script>

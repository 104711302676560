var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.MethodConfig[_vm.method], {
    tag: "component",
    class: { repay_paychash: _vm.isMultiPay, repay: !_vm.isMultiPay },
    attrs: {
      "repay-info": _vm.repayInfoProps,
      "pay-type":
        Array.isArray(_vm.repayInfoProps) && _vm.repayInfoProps.length > 1
          ? "RepayBaseList"
          : "RepayBase",
      "channel-list": _vm.repayInfo.channelList,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
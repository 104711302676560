var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-overlay",
    {
      staticClass: "ec-overlay",
      attrs: { "lock-scroll": _vm.lockScroll, show: _vm.visible },
      on: { click: _vm.handleClick },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.MethodConfig[_vm.payType],
    {
      tag: "component",
      staticClass: "paynet",
      attrs: {
        "repay-info": _vm.repayInfo,
        "account-number": _vm.accountNumber,
        "pay-desc": _vm.repayInfo.channelName,
        "code-desc": _vm.getI18n("paynetCodeDesc"),
        "instruction-key": "PAYNET_CASH_INSTRUCTIONS",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "store-wrapper",
          attrs: { slot: "extra" },
          slot: "extra",
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.getI18n("stores")) + "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "store-card" }, [
            _c("img", {
              attrs: { src: require("./img/paynetstore.png"), alt: "" },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2021-3-16 14:41:39 -->
<!-- @desc base.vue -->

<template>
    <div class="repay-base">
        <div class="money-card">
            <span class="money-title">
                {{ getI18n('payTitle') }}
            </span>
            <span>
                <span
                    class="money-discount"
                    :style="{color: moneyColor}"
                >
                    $ {{ discountMoney | numberWithCommasFixed2 }}
                </span>
                <del
                    v-if="showAllMoney"
                    class="money-all"
                >
                    $ {{ allMoney | numberWithCommasFixed2 }}
                </del>
            </span>
        </div>
        <div class="account-card">
            <div class="number-code">
                <span>{{ getI18n('accountCode') }}</span>
                <div class="time-desc">
                    {{ timeDesc }}
                </div>
                <canvas
                    id="code"
                />
                <div class="account-code">
                    {{ accountNumber | accountNumber(splitSign) }}
                </div>
            </div>
        </div>

        <slot name="extra" />

        <div
            v-if="instructionKey"
            class="instruction"
        >
            <yqg-md-preview :md-text="instruction.content || ''" />
        </div>
    </div>
</template>

<script>
import JsBarcode from 'jsbarcode';

import StaticText from 'ssr-common/resource/static-text';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import prefetch from 'ssr-common/vue/mixin/server-prefetch';

import payMoney from './mixin/pay-money';

export default {
    name: 'RepayBase',

    filters: {
        accountNumber(number, splitSign) {
            return number?.replace(/(.{4})/g, `$1${splitSign}`);
        }
    },

    mixins: [prefetch('instruction'), payMoney],

    props: {
        accountNumber: {
            type: String,
            default: ''
        },

        splitSign: {
            type: String,
            default: ' '
        },

        timeDesc: {
            type: String,
            default: ''
        },

        instructionKey: {
            type: String,
            default: ''
        }
    },

    watch: {
        accountNumber: {
            handler(val) {
                if (!this.$isServer) {
                    this.$nextTick(() => {
                        JsBarcode('#code', val, {
                            displayValue: false,
                            margin: 0
                        });
                    });
                }
            },
            immediate: true
        }
    },

    methods: {
        getI18n(text) {
            return this.$t(`webview.repay.${text}`);
        },

        async initFetch() {
            const {data: {body: {content}}} = await StaticText.getByKey({
                params: {key: this.instructionKey}
            }).catch(() => ({data: {body: {content: ''}}}));
            const hrefIndex = content.indexOf('href="');
            const arrowIndex = content.substring(hrefIndex).indexOf('">');
            const url = content.substring(hrefIndex + 6, hrefIndex + arrowIndex);

            return {content, url};
        },

        handleCopy() {
            WebviewInterface.copyToClipboard(this.accountNumber);
            this.$toast.success(this.getI18n('copySuccess'));
        }
    }
};
</script>

<style lang="scss" scoped>
.repay-base {
    .header {
        display: flex;
        align-items: center;

        .desc-wrapper {
            margin-left: 0.15rem;
        }
    }

    .money-card {
        margin-top: 0.1rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 0.2rem 0.2rem 0.12rem;
        background-color: #fff;
        box-shadow: 0 2px 10px 0 rgba(37, 51, 56, 20%);
        border-radius: 8px;

        .money-title {
            font-size: 14px;
            font-family: Helvetica;
            color: #999;
            line-height: 18px;
            margin-bottom: 0.05rem;
        }

        .money-discount {
            font-size: 18px;
            font-family: "Helvetica-Bold", Helvetica;
            font-weight: bold;
            line-height: 25px;
        }

        .money-all {
            font-size: 12px;
            font-family: Helvetica;
            color: #999;
            line-height: 16px;
            margin-left: 0.1rem;
        }
    }

    .account-card {
        margin-top: 0.16rem;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 6%);
        border-radius: 8px;
        background: #fff;
        font-size: 12px;
        color: #999;
        line-height: 16px;

        .number-code {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.2rem;

            .time-desc {
                color: #f5222d;
            }

            #code {
                margin-top: 0.2rem;
                height: 0.76rem;
                width: 2.5rem;
            }

            .account-code {
                font-size: 18px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #333;
                line-height: 25px;
                margin-top: 0.12rem;
            }
        }
    }

    .instruction {
        margin-top: 0.28rem;

        ::v-deep ol {
            list-style-type: decimal;
        }
    }
}
</style>

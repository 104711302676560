/*
 * @Author: zhaoyang
 * @Date: 2022-10-11 17:43:19
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2022-10-11 17:58:35
 */

export const numSub = (num1, num2) => {
    let baseNum1;
    let baseNum2;
    try {
        baseNum1 = num1.toString().split('.')[1].length;
    } catch (err) {
        baseNum1 = 0;
    }

    try {
        baseNum2 = num2.toString().split('.')[1].length;
    } catch (err) {
        baseNum2 = 0;
    }

    const baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    const precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;

    return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
};
